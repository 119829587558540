import { px2em } from "ksk/styledComponents/functions";
import styled from "styled-components"

const BuscaRapidaIdSiconfi = styled.section`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 0em;
    // position: relative;
    width:100%;
    justify-content:end;

    #busca-idsiconfi-form__input-idsiconfi{
        width:11em;
        color:white;
    }
    
    #busca-idsiconfi-form__input-idsiconfi-label{
        color:white;
    }
    
    #busca-idsiconfi-form__input-idsiconfi-helper-text{
        color:white;
    }

    #btn-pesquisar{
        background: rgba(255, 255, 255, 0.1);
        border: ${px2em(1)} solid rgba(255,255,255,0.2);
        :hover {
            border: ${px2em(1)} solid rgba(255,255,255,0.8);
        }
    }

    .MuiInput-underline::before{
        border-bottom:1px solid rgba(255, 255, 255, 0.2)
    }

    .MuiInput-underline:hover::before{
        border-bottom:2px solid rgba(255, 255, 255, 0.2)
    }

    .MuiInput-underline.Mui-error::after{
        border-bottom-color:white
    }

`;

export const S = {
    BuscaRapidaIdSiconfi,
}