import GenericHttpService from './GenericHttpService';

const emptyObject = {
   id: null,
   dividaTipo:null,
   dividaDestinacao: '',
   devedorInfo: {
    listaDevedorTipo: [],
    listaTipoEnte: [],
    listaSiglaUF: []
   },
   credor:{
     listaTipoPj:[],
     listaTipoEnte:[],
     listaSiglaUF: [],
   }
};

export default {
  ...GenericHttpService('coc'),
  getEmptyObject: () => ({...emptyObject})
};