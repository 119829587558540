import acessoInformacao from 'ksk/assets/template/acesso_informacao.png';
import logoSerpro from 'ksk/assets/template/marca-serpro-2023/assinatura-serpro-govfederal-horiz-negativo-01.png';
import logoMinisterioFazenda from 'ksk/assets/template/marca-serpro-2023/assinatura-serpro-govfederal-horiz-negativo-02.png';

import logoSerproVertical from 'ksk/assets/template/marca-serpro-2023/assinatura-serpro-govfederal-vert-negativo-01.png';
import logoMinisterioFazendaVertical from 'ksk/assets/template/marca-serpro-2023/assinatura-serpro-govfederal-vert-negativo-02.png';




import logoTesouro from 'ksk/assets/template/tesouro-rodape.png';
import React from 'react';
import { S } from './MainFooter.styles';
import useWindowSize from 'ksk/hooks/useWindowSize';

function MainFooter() {

  const { isPhone } =  useWindowSize();

  const ambiente = `${process.env.REACT_APP_AMBIENTE}`
  const dtPublicacao = `${process.env.REACT_APP_DTPUBLICACAO}`
  const idPipeline = `${process.env.REACT_APP_IDPIPELINE}`

  return (
    <S.Footer tabIndex="0" id="main-footer">
      <section className="footer__banners" role="banner">    
        <a href="https://www.gov.br/tesouronacional/" id="link-main-footer-tesouro-1" target="_blank">
          <img alt="Logotipo do Tesouro Nacional" src={ logoTesouro }/>
        </a>

        {
          isPhone 
          ? (
              <div className="footer__stack-vertical">
                <a href="https://www.serpro.gov.br/" id="link-main-footer-serpro" target="_blank">
                  <figure></figure>
                  <img alt="Logo do Serpro" src={ logoSerproVertical }/>
                </a>

                <a href="https://www.gov.br/fazenda/" id="link-main-footer-serpro" target="_blank">
                  <img alt="Logo do Ministério da Fazenda" src={ logoMinisterioFazendaVertical }/>
                </a>
              </div>
          )
          : (
            <div className="footer__stack-horizontal">
              <a href="https://serpro.gov.br/" id="link-main-footer-serpro" target="_blank">
                <img className="footer__serpro__logo"alt="Logo do Serpro" src={ logoSerpro }/>
              </a>

              <a href="https://www.gov.br/fazenda/" id="link-main-footer-serpro" target="_blank">
                <img className="footer__serpro__logo" alt="Logo do Ministério da Fazenda" src={ logoMinisterioFazenda }/>
              </a>
            </div>
          )
        }
       
        <var className="footer__versao-aplicacao">
          <div className="display-none">{ ambiente }</div>
          <div>Versão 1.15.3/{idPipeline}</div>
          <div>{ dtPublicacao }</div>
        </var>    
        
        <a href="https://www.gov.br/acessoainformacao/pt-br"  id="link-main-footer-acesso-informacao" target="_blank">
          <img alt="Logo do portal de acesso à informação" src={acessoInformacao}/>
        </a>
        
      </section>
    </S.Footer>
  )
}

export default MainFooter;
